<template>
  <section
    class="relative w-full px-5 mx-auto mb-20 md:px-10 max-w-screen-2xl 2xl:px-0"
  >
    <div class="flex flex-wrap items-stretch w-full mb-20">
      <div class="w-full mb-10 lg:pr-10 lg:w-1/2 lg:mb-0">
        <div class="mb-10">
          <h2 class="text-4xl font-black">點財神燈 迴向進財</h2>
          <p class="text-lg font-bold text-red-700">
            五路財神 招財進寶 納珍利市
          </p>
        </div>

        <div class="text-lg leading-loose">
          <p>
            《香山財神廟》以「武財神-趙公明」為首，率領五路財神坐鎮<b
              class="font-bold text-yellow-700"
              >財神燈</b
            >，將此絕佳風水地理之金銀財寶，分配賜與世間所需之人。<br />因此奉武財神指示，特別開放為十方善信大德祈點<b
              class="font-bold text-yellow-700"
              >財神燈</b
            >，點燈者祈點上燈安於<b class="font-bold text-yellow-700">財神燈</b
            >上，能使我們的元辰光彩，隨時受金山銀海財龍鉅寶穴的風水地氣加持，並由五路財神全年365天，天天護持庇佑！<br />修補先天財庫破損、興旺個人後天財氣，照亮財路順遂通暢
          </p>
        </div>
      </div>
      <div class="w-full lg:w-1/2">
        <img src="/img/home/財神燈.webp" class="w-full" />
      </div>
    </div>

    <div
      class="relative px-10 py-20 -mx-5 overflow-hidden text-center text-white bg-red-700 md:-mx-10 md:px-20 2xl:mx-0"
    >
      <div class="relative z-10 w-full">
        <h3 class="mb-5 text-2xl font-bold text-yellow-400">
          為什麼需要點財神燈？
        </h3>
        <p class="max-w-screen-md mx-auto text-lg leading-loose">
          六財神燈：又稱「財利燈」、「旺財燈」，祈求財神爺來點亮財富之路，祈求事業經營順利、財運亨通、財源廣進，財富圓滿豐收、財運煥然一新、財星高照、財
          喜盈門、萬事迪吉、貴人扶助、來年富利，適合各行各業祈求錢財源源不絕者。
        </p>
      </div>
      <div
        class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-repeat opacity-30"
        style="
          background-image: url('/img/home/背景.svg');
          background-size: 300px;
        "
      ></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WealthLightIntro",
};
</script>