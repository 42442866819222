<template>
  <div
    id="MainLoading"
    ref="MainContent"
    class="fixed top-0 bottom-0 z-50 flex items-center justify-center w-full left-full"
  >
    <div data-dialog-box class="relative z-10 text-center">
      <div class="relative z-50 inline-block w-20 h-20 lds-ellipsis">
        <div class="bg-red-700 absolute top-[33px] w-3 h-3 rounded-[50%]"></div>
        <div class="bg-red-700 absolute top-[33px] w-3 h-3 rounded-[50%]"></div>
        <div class="bg-red-700 absolute top-[33px] w-3 h-3 rounded-[50%]"></div>
        <div class="bg-red-700 absolute top-[33px] w-3 h-3 rounded-[50%]"></div>
      </div>
    </div>
    <div
      data-dialog-background
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-black bg-opacity-50"
    ></div>
  </div>
</template>

<script>
import { Dialog } from "@/gsap/MainDialog";
export default {
  name: "MainLoading",
  data() {
    return {
      loading_gsap: null,
    };
  },
  watch: {
    status(new_val, old_val) {
      if (old_val == 0 && new_val != 0) {
        // open
        this.loading_gsap.open();
      } else if (old_val != 0 && new_val == 0) {
        // close
        this.loading_gsap.close();
      }
    },
  },
  computed: {
    status() {
      return this.$store.state.loading;
    },
  },
  mounted() {
    this.loading_gsap = new Dialog(this.$refs.MainContent);
    this.loading_gsap.reset();
  },
};
</script>

<style>
.lds-ellipsis div {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
