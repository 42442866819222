<template>
  <section class="w-full mb-20">
    <div class="flex flex-wrap items-stretch w-full">
      <div class="w-full md:w-1/3">
        <img src="/img/home/index01.webp" class="block w-full" />
      </div>
      <div class="w-full md:w-1/3">
        <img src="/img/home/index02.webp" class="block w-full" />
      </div>
      <div class="w-full md:w-1/3">
        <img src="/img/home/index03.webp" class="block w-full" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeGallery",
};
</script>