<template>
  <section
    class="relative w-full px-5 mx-auto mb-20 md:px-10 max-w-screen-2xl 2xl:px-0"
  >
    <div class="w-full">
      <div class="w-full mb-10">
        <div class="flex justify-center">
          <h2 class="inline-block mb-10 text-4xl font-black md:text-5xl">
            <span class="block mb-2 text-xl text-red-700 md:text-2xl"
              >恭祝</span
            >
            天官武財神 玄壇真君
            <span class="block mt-2 text-xl text-right text-red-700 md:text-3xl"
              >聖誕千秋</span
            >
          </h2>
        </div>

        <div class="text-lg leading-loose sm:text-center">
          <p>
            農曆三月十五日是
            <b class="text-xl font-black text-yellow-700">
              天官武財神 玄壇真君</b
            >
            的聖誕日。
          </p>
          <p>
            新竹香山財神廟供奉天官五路武財神-趙元帥，姓趙名朗字公明，道教諸真之一，道諱「上清如意金輪院正一玄壇趙元帥」<br />
            道教傳說趙公明是「上天皓庭宵度天慧覺昏梵氣」所化生，故在《典籍實錄》上說祂是日之精。<br />
            是道教神系的護法神之一，皓封《天將》《上清正一玄壇趙元帥》
          </p>
        </div>
      </div>
      <div class="w-full mb-20">
        <img
          src="/img/home/LINE_ALBUM_香山財神廟-素材_240321_8.webp"
          class="block w-full"
        />
      </div>

      <div class="w-full">
        <div class="mb-10 text-center">
          <h2 class="mb-5 text-4xl font-black">
            香山財神廟 <b class="text-red-700">財神殿</b>
          </h2>
          <p
            class="inline-block px-4 py-1 text-lg font-bold text-red-700 border border-red-700"
          >
            天官五路財神
          </p>
        </div>
        <div class="w-full">
          <ol class="flex flex-wrap items-stretch justify-center -mx-4">
            <li
              v-for="(item, item_index) in god_intro_list"
              :key="`god_${item_index}`"
              class="w-1/2 px-4 mb-8 md:w-1/3 lg:w-1/5"
            >
              <div class="relative w-full">
                <img :src="item.image" class="block object-cover w-full" />
              </div>
              <h3 class="mt-5 text-lg font-bold text-center">
                {{ item.title }}
              </h3>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "GodIntro",

  data() {
    return {
      god_intro_list: [
        {
          title: "北路財神-利市仙官",
          image: "/img/home/about02_1.webp",
        },
        {
          title: "西路財神-納珍天尊",
          image: "/img/home/about02_5.webp",
        },
        {
          title: "中路財神-玄壇真君",
          image: "/img/home/about02_2.webp",
        },
        {
          title: "南路財神-進寶天尊",
          image: "/img/home/about02_3.webp",
        },
        {
          title: "東路財神-招財使者",
          image: "/img/home/about02_4.webp",
        },
      ],
    };
  },
};
</script>