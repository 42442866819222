<template>
  <footer class="relative z-10 w-full border-t border-yellow-700">
    <div class="relative w-full glitch_bg">
      <div
        class="relative z-10 flex items-center justify-between w-full px-5 py-4 mx-auto md:px-10 max-w-screen-2xl 2xl:px-0"
      >
        <ol class="flex items-center -mx-3">
          <li class="px-3" v-if="$GetColumn('company_facebook') != ''">
            <a
              :href="$GetColumn('company_facebook')"
              target="_blank"
              class="text-white transition-all duration-300 hover:text-yellow-400"
            >
              <i class="text-xl icon-facebook"></i>
            </a>
          </li>
          <li class="px-3" v-if="$GetColumn('company_instagram') != ''">
            <a
              :href="$GetColumn('company_instagram')"
              target="_blank"
              class="text-white transition-all duration-300 hover:text-yellow-400"
            >
              <i class="text-xl icon-instagram"></i>
            </a>
          </li>
          <li class="px-3" v-if="$GetColumn('company_line') != ''">
            <a
              :href="$GetColumn('company_line')"
              target="_blank"
              class="text-white transition-all duration-300 hover:text-yellow-400"
            >
              <i class="text-xl icon-line"></i>
            </a>
          </li>
        </ol>

        <button
          @click="ScrollToTop"
          class="flex items-center px-4 py-1 font-bold text-white transition-all duration-300 bg-red-900 hover:text-yellow-400"
        >
          回到頂部
        </button>
      </div>
      <div
        class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-red-700 mix-blend-overlay"
      ></div>
    </div>
    <div class="relative w-full glitch_bg">
      <div
        class="relative z-10 flex flex-wrap items-center justify-between w-full px-5 mx-auto md:px-10 max-w-screen-2xl 2xl:px-0 py-14"
      >
        <div
          class="flex flex-wrap items-stretch order-2 w-full lg:order-1 md:w-auto"
        >
          <!-- <div class="w-1/2 mb-10 md:w-auto md:mr-20 md:mb-0">
            <p class="mb-5 text-sm text-yellow-600">網站地圖</p>
            <ol>
              <li
                v-for="(item, item_index) in menu_list"
                :key="`menu_${item_index}`"
                class="mb-3"
              >
                <router-link
                  :to="item.link"
                  class="text-sm font-semibold text-white transition-all duration-300 hover:text-yellow-400"
                >
                  {{ item.title }}
                </router-link>
              </li>
            </ol>
          </div> -->

          <div class="w-1/2 mb-10 md:w-auto md:mr-20 md:mb-0">
            <p class="mb-5 text-sm text-yellow-600">條款與規範</p>
            <ol>
              <!-- <li class="mb-3">
                <router-link
                  to="/terms/terms_of_customer"
                  class="text-sm font-semibold text-white transition-all duration-300 hover:text-yellow-400"
                >
                  服務條款
                </router-link>
              </li> -->
              <li class="mb-3">
                <router-link
                  to="/terms/site_privacy"
                  class="text-sm font-semibold text-white transition-all duration-300 hover:text-yellow-400"
                >
                  隱私權政策
                </router-link>
              </li>
              <li class="mb-3">
                <router-link
                  to="/terms/return_policy"
                  class="text-sm font-semibold text-white transition-all duration-300 hover:text-yellow-400"
                >
                  退換貨政策
                </router-link>
              </li>
            </ol>
          </div>

          <div class="w-full md:w-auto md:mr-20">
            <p class="mb-5 text-sm text-yellow-600">聯絡資訊</p>
            <ol>
              <li class="flex items-center mb-3">
                <p class="mr-3 text-sm font-semibold text-white">聯絡電話</p>
                <p class="text-sm font-semibold text-yellow-600">
                  {{ $GetColumn("company_phone") }}
                </p>
              </li>
              <li class="flex items-center mb-3">
                <p class="mr-3 text-sm font-semibold text-white">公司地址</p>
                <p class="text-sm font-semibold text-yellow-600">
                  {{ $GetColumn("company_address") }}
                </p>
              </li>
              <li class="flex items-center mb-3">
                <p class="mr-3 text-sm font-semibold text-white">電子信箱</p>
                <p class="text-sm font-semibold text-yellow-600">
                  {{ $GetColumn("company_email") }}
                </p>
              </li>
              <li class="flex items-center mb-3">
                <p class="mr-3 text-sm font-semibold text-white">營業時間</p>
                <p class="text-sm font-semibold text-yellow-600">
                  {{ $GetColumn("company_time") }}
                </p>
              </li>
            </ol>
          </div>
        </div>

        <div
          class="flex justify-center order-1 w-full mb-14 lg:mb-0 lg:justify-end lg:flex-1 lg:order-2 lg:w-auto"
        >
          <router-link to="/">
            <img
              :src="$ImageUrl($GetColumn('footer_logo'))"
              class="w-52 md:w-64"
            />
          </router-link>
        </div>
      </div>
      <div
        class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-red-800 mix-blend-overlay"
      ></div>
    </div>
    <div class="relative w-full glitch_bg">
      <div
        class="relative z-10 flex flex-wrap items-center justify-center w-full px-5 py-4 mx-auto 2xl:px-0 max-w-screen-2xl md:justify-end md:px-10"
      >
        <img class="w-auto h-7" :src="$ImageUrl($GetColumn('payment_image'))" />
      </div>

      <div
        class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-red-800 mix-blend-overlay"
      ></div>
    </div>
    <div class="relative w-full glitch_bg">
      <div
        class="relative z-10 flex flex-wrap items-center justify-center w-full px-5 py-4 mx-auto 2xl:px-0 md:justify-between md:px-10 max-w-screen-2xl"
      >
        <p class="text-xs text-white">©2024 {{ $GetColumn("company_name") }}</p>

        <p class="text-xs text-white">
          DESIGN BY
          <a
            class="ml-2 text-yellow-400"
            href="https://www.yongxin-design.com"
            target="_blank"
            >泳欣事業有限公司</a
          >
        </p>
      </div>

      <div
        class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-red-900 mix-blend-overlay"
      ></div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      menu_list: [
        {
          title: "商品列表",
          sub_title: "Products",
          link: "/collections",
        },
        {
          title: "關於我們",
          sub_title: "About Us",
          link: "/about",
        },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        {
          title: "影音專區",
          sub_title: "Videos",
          link: "/video",
        },
        {
          title: "聯絡我們",
          sub_title: "Contact",
          link: "/contact",
        },
        {
          title: "非會員訂單查詢",
          sub_title: "Order Search",
          link: "/order/search",
        },
      ],
    };
  },
  methods: {
    ScrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
