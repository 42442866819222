<template>
  <div class="flex flex-col h-full p-10 border-2 border-red-700">
    <header class="w-full mb-5">
      <p class="mb-2 text-xl font-black text-red-700">線上登記祈福</p>
      <h2 class="text-3xl font-black">{{ product_data.Title }}</h2>
    </header>
    <div class="flex-1 mb-10">
      <p
        class="inline-block px-4 py-1 mb-5 text-lg font-bold text-red-700 border border-red-700"
      >
        NT${{ active_stock.SellPrice }} / 組
      </p>
      <div v-html="product_data.Memo1"></div>
    </div>
    <div class="w-full">
      <div class="">
        <p class="mb-2 font-bold">數量</p>
        <div
          class="relative flex items-stretch w-full overflow-hidden border border-black"
        >
          <button
            title="MinusAmount"
            @click="ChangeAmount(-1)"
            class="flex-shrink-0 w-[50px] text-white text-sm border-r border-black bg-red-700 duration-200 aspect-square transition-color hover:bg-white hover:text-red-700"
          >
            <i class="icon-minus"></i>
          </button>
          <input
            aria-label="Amount"
            class="w-full py-3 text-center bg-transparent focus:outline-none"
            type="text"
            :value="amount"
            readonly
          />
          <button
            title="PlusAmount"
            @click="ChangeAmount(1)"
            class="flex-shrink-0 w-[50px] text-sm border-l border-black text-white bg-red-700 duration-200 transition-color hover:bg-white hover:text-red-700"
          >
            <i class="icon-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    product_data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ChangeAmount(value) {
      this.$emit("change-amount", {
        product_data: this.product_data,
        active_option: this.active_option,
        amount: value,
      });
    },
  },
  computed: {
    active_stock() {
      return this.product_data.Stock[0];
    },
    active_option() {
      return [this.active_stock.ColorID, this.active_stock.SizeID];
    },
    amount() {
      const shop_cart_item = this.$store.state.shopcart_module.shopcart.filter(
        (item) => item.product_data.GoodsID == this.product_data.GoodsID
      );
      return shop_cart_item.length > 0 ? shop_cart_item[0].amount : 0;
    },
  },
};
</script>