<template>
  <div class="mb-5">
    <div class="flex flex-wrap items-center pb-2 mb-5 border-b border-black">
      <p class="font-bold">運送方式</p>

      <p v-if="GetError('ship_way')" class="ml-5 text-sm text-red-600">
        請選擇運送方式
      </p>
    </div>
    <ol class="flex flex-wrap items-stretch -mx-2">
      <li
        v-for="(item, item_index) in shipway_data"
        :key="`shipway_${item_index}`"
        class="px-2 mb-4"
      >
        <button
          @click="$emit('updateShipping', item.ShippingID)"
          class="block px-4 py-1 transition-colors duration-200 border border-black cursor-pointer hover:bg-zinc-300"
          :class="
            item.ShippingID == form_data.ship_way ? 'bg-red-700 text-white' : ''
          "
        >
          <span class="text-base font-bold">{{ item.Title }} </span>
        </button>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "ShippingList",
  props: {
    form_data: {
      type: Object,
    },
    errors: {
      type: Array,
    },
    checkout_data: {
      type: Object,
    },
  },
  methods: {
    GetError(key) {
      return this.errors.indexOf(key) != -1;
    },
  },
  computed: {
    // 取得物流方式
    shipway_data() {
      let shipway_list = JSON.parse(
        JSON.stringify(this.$store.state.shipway_data)
      );

      // 過濾停用與刪除的運送方式
      shipway_list = shipway_list.filter(
        (item) => item.Status == "Y" && item.deleted_at == null
      );
      // 如果目前商品中有低溫商品，則過濾出所有低溫物流
      if (this.has_forzen_product) {
        shipway_list = shipway_list.filter(
          (item) => item.DeliveryFrozen == "Y"
        );
      }
      // 如果目前商品中沒有低溫商品，則過濾出所有非低溫物流
      else {
        shipway_list = shipway_list.filter(
          (item) => item.DeliveryFrozen == "N"
        );
      }
      // 過濾出積材上限比目前購物車積材總合高的物流
      if (this.checkout_data != null) {
        shipway_list = shipway_list.filter(
          (item) =>
            item.DeliverVolumeMax >= this.checkout_data.TotalDeliverVolume &&
            item.DeliverWeightMax >= this.checkout_data.TotalDeliverWeight
        );
      }

      return shipway_list;
    },
    // 選中的物流方式
    active_shipway_data() {
      if (this.form_data.ship_way == "") {
        return null;
      } else {
        return this.shipway_data.filter(
          (item) => item.ShippingID == this.form_data.ship_way
        )[0];
      }
    },
  },
};
</script>