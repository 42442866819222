<template>
  <main class="relative w-full bg-repeat glitch_bg">
    <div class="relative z-10">
      <div class="relative w-full mx-auto mb-10 md:mb-20 max-w-screen-2xl">
        <img src="/img/home/banner@pc.webp" class="hidden w-full md:block" />
        <img src="/img/home/banner@mb.webp" class="block w-full md:hidden" />
      </div>
      <GodIntro />
      <WealthLightIntro />
      <ProductIntro />
      <Gallery />
      <TempleIntro />
      <ShopSection />
    </div>

    <div
      class="fixed z-0 flex items-center justify-center w-full h-full transform -translate-x-1/2 -translate-y-1/2 opacity-10 top-1/2 left-1/2"
    >
      <img src="/img/home/龍.svg" class="w-full max-w-screen-xl" />
    </div>
  </main>
</template>

<script>
import GodIntro from "@/components/home/GodIntro.vue";
import WealthLightIntro from "@/components/home/WealthLightIntro.vue";
import ProductIntro from "@/components/home/ProductIntro.vue";
import Gallery from "@/components/home/Gallery.vue";
import TempleIntro from "@/components/home/TempleIntro.vue";
import ShopSection from "@/components/home/ShopSection.vue";
export default {
  name: "HomeView",
  components: {
    GodIntro,
    WealthLightIntro,
    ProductIntro,
    Gallery,
    TempleIntro,
    ShopSection,
  },
  created() {
    const meta_data = this.$store.getters.page_meta_data("首頁");
    this.$PageInit(
      this,
      meta_data.Title,
      meta_data.Content,
      meta_data.Image == "" ? "" : this.$ImageUrl(meta_data.Image)
    );
  },
};
</script>
