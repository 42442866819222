import store from "@/store/index";
import router from "@/router/index";

export function GetBaseMetaData() {
  const meta_data_list = store.state.page_meta_data;
  let base_meta_data = meta_data_list.filter(
    (item) => item.PageTitle == "所有頁面"
  )[0];
  base_meta_data.Content = base_meta_data.Content.replaceAll(/<[^>]+>/g, "");
  if (base_meta_data.Content.length > 155) {
    base_meta_data.Content = base_meta_data.Content.slice(0, 155) + "...";
  }

  return {
    title: base_meta_data.Title,
    content: base_meta_data.Content,
    image: GetImageUrl(base_meta_data.Image),
  };
}

export function GetMetaData(title, content = "", image = "") {
  const default_meta = GetBaseMetaData();
  title == "" ? (title = "官方網站") : "";

  return {
    title: title + default_meta.title,
    meta: [
      {
        property: "og:url",
        name: "og:url",
        content: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
        vmid: "og:url",
      },
      {
        property: "og:title",
        name: "og:title",
        content: title + default_meta.title,
        vmid: "og:title",
      },
      {
        property: "og:description",
        name: "og:description",
        content: content == "" ? default_meta.content : content,
        vmid: "og:description",
      },
      {
        property: "twitter:description",
        name: "twitter:description",
        content: content == "" ? default_meta.content : content,
        vmid: "twitter:description",
      },
      {
        property: "description",
        name: "description",
        content: content == "" ? default_meta.content : content,
        vmid: "description",
      },
      {
        property: "og:image",
        name: "og:image",
        content: image == "" ? default_meta.image : image,
        vmid: "og:image",
      },
      {
        property: "apple-mobile-web-app-title",
        name: "apple-mobile-web-app-title",
        content: title + default_meta.title,
        vmid: "apple-mobile-web-app-title",
      },
      {
        property: "application-name",
        name: "application-name",
        content: title + default_meta.title,
        vmid: "application-name",
      },
      {
        property: "og:site_name",
        name: "og:site_name",
        content: "官方網站" + default_meta.title,
        vmid: "og:site_name",
      },
      {
        property: "og:type",
        name: "og:type",
        content: "website",
        vmid: "og:type",
      },
      {
        property: "og:locale",
        name: "og:locale",
        content: "zh_tw",
        vmid: "og:locale",
      },
    ],
  };
}

function GetImageUrl(item) {
  return item == "" ? "" : process.env.VUE_APP_BASE_API + item;
}
