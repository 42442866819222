<template>
  <section
    class="relative z-10 w-full px-5 mx-auto mb-20 md:px-10 max-w-screen-2xl 2xl:px-0"
  >
    <header class="w-full mb-20 text-center">
      <h2 class="mb-5 text-4xl font-black">香山財神廟</h2>
      <p
        class="inline-block px-4 py-1 text-lg font-bold text-red-700 border border-red-700"
      >
        關於本廟
      </p>
    </header>

    <div class="flex flex-wrap items-center w-full mb-28">
      <div class="flex justify-center w-full mb-10 lg:w-1/2 lg:px-10 lg:mb-0">
        <div>
          <p class="mb-10 text-lg leading-loose">
            香山財神廟為<b class="text-xl text-yellow-700"
              >新竹第一合法登記的財神廟（也是目前唯一的）</b
            ><br />
            本廟於民國36年破土，<b class="text-xl text-yellow-700"
              >先蔣總統 經國先生</b
            >來新竹必訪景點！<br />
            本廟地處財龍穴的風水寶地。其著名的「香山觀海」，被新竹市政府編訂為風景區，<br />
            常來的香客都說：「台灣最美的風景，是人在香山觀海。」
          </p>
          <p class="inline-block text-lg leading-loose">
            農曆三月十五日是<b class="text-xl font-black text-yellow-700"
              >天官武財神 玄壇真君</b
            >的聖誕日 為香山財神廟年度經典盛事！ 每年將於此日舉辦眾多
            <b class="text-xl font-black text-yellow-700">祝壽慶典活動</b
            >為財神慶賀並結合正統道教科儀接引財氣、修補財庫獨門祕法，祈請財神爺大開<b
              class="text-xl font-black text-yellow-700"
              >天官財庫門</b
            >，讓我們接財補庫、運勢大開、興旺各方財祿！
          </p>
        </div>
      </div>
      <div class="w-full lg:w-1/2">
        <img src="/img/home/templeIntro01.webp" class="block w-full" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TempleIntro",
};
</script>