<template>
  <div>
    <p class="mb-2 font-bold">購買人資訊</p>
    <div class="flex flex-wrap items-center mb-6 -mx-2">
      <div class="w-1/2 px-2 mb-4">
        <input
          type="text"
          name="name"
          :value="form_data.buyer_name"
          @input="UpdateForm('buyer_name', $event.target.value)"
          class="relative z-0 w-full px-4 py-3 border border-black appearance-none focus:outline-primary"
          placeholder="名字"
        />
        <p v-if="GetError('buyer_name')" class="text-xs text-red-600">
          請輸入正確的中文姓名
        </p>
      </div>
      <div class="w-1/2 px-2 mb-4">
        <input
          type="tel"
          name="phone"
          :value="form_data.buyer_phone"
          @input="UpdateForm('buyer_phone', $event.target.value)"
          class="relative z-0 w-full px-4 py-3 border border-black appearance-none focus:outline-primary"
          placeholder="手機號碼"
        />
        <p v-if="GetError('buyer_phone')" class="text-xs text-red-600">
          請輸入正確的手機號碼
        </p>
      </div>
      <div class="w-full px-2 mb-4">
        <input
          type="email"
          name="email"
          :value="form_data.consignee_email"
          @input="UpdateForm('consignee_email', $event.target.value)"
          class="relative z-0 w-full px-4 py-3 border border-black appearance-none focus:outline-primary"
          placeholder="電子郵件"
        />
        <p v-if="GetError('consignee_email')" class="text-xs text-red-600">
          請輸入正確的電子郵件
        </p>
      </div>
      <div class="w-full px-2">
        <span class="block w-full border-b border-black"></span>
      </div>
    </div>
    <!-- buyer -->

    <!-- resiver -->
    <div class="flex flex-wrap items-center justify-between mb-5">
      <p class="mb-2 font-bold">收件人資訊</p>
      <div class="flex items-center w-full sm:w-auto">
        <button
          @click="$emit('set-samebuyer')"
          class="px-3 py-2 text-xs font-semibold text-white transition-colors duration-200 border xs:px-5 xs:text-sm bg-primary hover:md:bg-transparent border-primary hover:md:text-primary"
        >
          同購買人
        </button>
      </div>
    </div>
    <div class="flex flex-wrap items-center mb-6 -mx-2">
      <div class="w-1/2 px-2 mb-4">
        <input
          type="text"
          name="name"
          :value="form_data.consignee_name"
          @input="UpdateForm('consignee_name', $event.target.value)"
          class="relative z-0 w-full px-4 py-3 border border-black appearance-none focus:outline-primary"
          placeholder="名字"
        />
        <p v-if="GetError('consignee_name')" class="text-xs text-red-600">
          請輸入正確的中文姓名
        </p>
      </div>
      <div class="w-1/2 px-2 mb-4">
        <input
          type="tel"
          name="phone"
          :value="form_data.consignee_phone"
          @input="UpdateForm('consignee_phone', $event.target.value)"
          class="relative z-0 w-full px-4 py-3 border border-black appearance-none focus:outline-primary"
          placeholder="手機號碼"
        />
        <p v-if="GetError('consignee_phone')" class="text-xs text-red-600">
          請輸入正確的手機號碼
        </p>
      </div>
      <div v-if="!if_shipway_c2c" class="w-1/2 px-2 mb-4">
        <div class="relative">
          <label class="absolute z-10 text-xs top-2 left-4 text-basic_gray"
            >縣市</label
          >
          <i
            class="absolute z-10 text-black transform -translate-y-1/2 pointer-events-none top-1/2 right-4 icon-chevron-down"
          ></i>
          <select
            :value="form_data.consignee_city"
            name="city"
            @input="UpdateForm('consignee_city', $event.target.value)"
            class="relative z-0 w-full px-4 py-3 pt-6 pr-10 border border-black appearance-none focus:outline-primary"
          >
            <option value="">選擇縣市</option>
            <option v-for="item in city_list" :key="item">{{ item }}</option>
          </select>
        </div>
        <p v-if="GetError('consignee_city')" class="text-xs text-red-600">
          請選擇縣市
        </p>
      </div>
      <div v-if="!if_shipway_c2c" class="w-1/2 px-2 mb-4">
        <div class="relative">
          <i
            class="absolute z-10 text-black transform -translate-y-1/2 pointer-events-none top-1/2 right-4 icon-chevron-down"
          ></i>
          <label class="absolute z-10 text-xs top-2 left-4 text-basic_gray"
            >鄉鎮區域</label
          >
          <select
            :value="form_data.consignee_area"
            name="area"
            @input="UpdateForm('consignee_area', $event.target.value)"
            class="relative z-0 w-full px-4 py-3 pt-6 pr-10 border border-black appearance-none focus:outline-primary"
          >
            <option value="">選擇區域</option>
            <option v-for="item in area_list" :key="item">{{ item }}</option>
          </select>
        </div>
        <p v-if="GetError('consignee_area')" class="text-xs text-red-600">
          請選擇區域
        </p>
      </div>
      <input value="台灣" name="hidden_country" class="hidden" />
      <input :value="active_zip_code" name="hidden_zip_code" class="hidden" />
      <div v-if="!if_shipway_c2c" class="w-full px-2 mb-4">
        <input
          type="text"
          name="address"
          :value="form_data.consignee_address"
          @input="UpdateForm('consignee_address', $event.target.value)"
          class="relative z-0 w-full px-4 py-3 border border-black appearance-none focus:outline-primary"
          placeholder="詳細地址"
        />
        <p v-if="GetError('consignee_address')" class="text-xs text-red-600">
          請輸入地址
        </p>
      </div>
      <div class="w-full px-2">
        <span class="block w-full border-b border-black"></span>
      </div>
    </div>
    <!-- resiver -->
    <p class="mb-2 font-bold">
      訂單備註
      <span class="font-bold text-red-700"
        >(請留下出生年月日，填寫疏文時使用)</span
      >
    </p>
    <div class="flex flex-wrap items-center mb-6 -mx-2">
      <div class="w-full px-2 mb-4">
        <textarea
          name="comment"
          :value="form_data.comment"
          @input="UpdateForm('comment', $event.target.value)"
          class="relative z-0 w-full px-4 py-3 appearance-none border border-black focus:outline-primary min-h-[150px]"
          placeholder="留言"
        ></textarea>
        <p v-if="GetError('comment')" class="text-xs text-red-600">
          請留下出生年月日，填寫疏文需要
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserForm",
  props: {
    form_data: {
      type: Object,
    },
    errors: {
      type: Array,
    },
    checkout_data: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    GetError(key) {
      return this.errors.indexOf(key) != -1;
    },
    UpdateForm(key, val) {
      this.$LoadAllFont();
      this.$emit("update-form", { key: key, val: val });
    },
  },
  computed: {
    // 縣市、郵遞區號資料
    zipcode_data() {
      return this.$store.state.zipcode_data;
    },
    active_zip_code() {
      const zip_code = this.zipcode_data.filter(
        (item) =>
          item.Area == this.form_data.consignee_area &&
          item.City == this.form_data.consignee_city
      );
      return zip_code.length > 0 ? zip_code[0].ZipCode : "";
    },
    // 取得城市資料
    city_list() {
      let city = [];
      this.zipcode_data.forEach((item) => {
        let if_exist = city.indexOf(item.City) != -1;
        if (!if_exist) {
          city.push(item.City);
        }
      });
      return city;
    },
    // 取得目前選擇縣市底下的所有區域
    area_list() {
      // 若城市還未選取，則回傳空陣列
      if (this.form_data.consignee_city == "") {
        return [];
      }
      // 過濾目前城市底下的所有區域
      else {
        let area_list = [];
        this.zipcode_data.forEach((item) => {
          if (item.City == this.form_data.consignee_city) {
            area_list.push(item.Area);
          }
        });
        return area_list;
      }
    },
    // 目前選中物流是否為超商物流
    if_shipway_c2c() {
      if (this.active_shipway_data == null) {
        return false;
      } else if (
        this.active_shipway_data.ShippingType == "UNIMARTC2C" ||
        this.active_shipway_data.ShippingType == "FAMIC2C"
      ) {
        return true;
      } else {
        return false;
      }
    },

    // 取得物流方式
    shipway_data() {
      let shipway_list = JSON.parse(
        JSON.stringify(this.$store.state.shipway_data)
      );

      // 過濾停用與刪除的運送方式
      shipway_list = shipway_list.filter(
        (item) => item.Status == "Y" && item.deleted_at == null
      );
      // 如果目前商品中有低溫商品，則過濾出所有低溫物流
      if (this.has_forzen_product) {
        shipway_list = shipway_list.filter(
          (item) => item.DeliveryFrozen == "Y"
        );
      }
      // 如果目前商品中沒有低溫商品，則過濾出所有非低溫物流
      else {
        shipway_list = shipway_list.filter(
          (item) => item.DeliveryFrozen == "N"
        );
      }
      // 過濾出積材上限比目前購物車積材總合高的物流
      if (this.checkout_data != null) {
        shipway_list = shipway_list.filter(
          (item) =>
            item.DeliverVolumeMax >= this.checkout_data.TotalDeliverVolume &&
            item.DeliverWeightMax >= this.checkout_data.TotalDeliverWeight
        );
      }

      return shipway_list;
    },
    // 選中的物流方式
    active_shipway_data() {
      if (this.form_data.ship_way == "") {
        return null;
      } else {
        return this.shipway_data.filter(
          (item) => item.ShippingID == this.form_data.ship_way
        )[0];
      }
    },
  },
};
</script>