<template>
  <section
    class="relative w-full px-5 mx-auto mb-20 md:px-10 max-w-screen-2xl 2xl:px-0"
  >
    <header class="w-full mb-10 text-center">
      <p class="mb-2 text-2xl font-black text-red-700">香山財神廟</p>
      <h2 class="mb-5 text-4xl font-black">玄壇真君聖誕千秋 迴向進財組合</h2>
      <p
        class="inline-block px-4 py-1 text-lg font-bold text-red-700 border border-red-700"
      >
        組合內容介紹
      </p>
    </header>

    <div class="w-full">
      <ol class="flex flex-wrap items-stretch mb-20 -mx-4">
        <li
          v-for="(item, item_index) in product_list"
          :key="`product_${item_index}`"
          class="w-1/2 px-4 mb-8 lg:w-1/4"
        >
          <div class="mb-5 overflow-hidden rounded-full aspect-square">
            <img
              :src="item.image"
              class="object-cover w-full h-full transition-all duration-500 transform md:hover:scale-110"
            />
          </div>
          <p class="font-black text-center md:text-xl">
            {{ item.title }}
          </p>
        </li>
      </ol>
      <div class="flex flex-wrap justify-center">
        <div class="relative mb-5">
          <button
            @click="ScrollToEl"
            class="relative z-10 px-10 py-3 text-2xl font-black text-white transition-all duration-500 bg-red-700 border border-transparent md:hover:bg-white md:hover:text-red-700 md:hover:border-red-700"
          >
            立即線上登記
          </button>
          <span class="wave_shadow"></span>
          <span class="wave_shadow second"></span>
        </div>

        <p class="w-full text-sm font-bold text-center md:text-base">
          ＊招財符令金牌、武財神鑰匙圈，加持完後會陸續寄出＊
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProductIntro",
  data() {
    return {
      product_list: [
        {
          title: "補財庫金紙組-含疏文",
          image: "/img/home/1.webp",
        },
        {
          title: "個人財神燈乙盞",
          image: "/img/home/4.webp",
        },
        {
          title: "招財符令金牌",
          image: "/img/home/3.webp",
        },
        {
          title: "玄壇真君-符令鑰匙圈",
          image: "/img/home/2.webp",
        },
      ],
    };
  },
  methods: {
    ScrollToEl() {
      var element = document.getElementById("ShopSection");
      var headerOffset = 100;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.wave_shadow {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: shadow_grow 2s infinite linear;
}
.wave_shadow.second {
  animation: shadow_grow 2.5s infinite linear;
}

@keyframes shadow_grow {
  0% {
    box-shadow: 0 0 0px 0px rgba(255, 0, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 20px 15px rgba(255, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 30px 25px rgba(255, 0, 0, 0);
  }
}
</style>