<template>
  <section
    id="ShopSection"
    class="relative z-10 w-full px-5 pb-20 mx-auto md:px-10 max-w-screen-2xl 2xl:px-0"
  >
    <header class="w-full mb-10 text-center">
      <p class="mb-2 text-2xl font-black text-red-700">線上登記祈福</p>
      <h2 class="mb-5 text-4xl font-black">玄壇真君聖誕千秋</h2>
      <p
        class="inline-block px-4 py-1 text-lg font-bold text-red-700 border border-red-700"
      >
        選擇祈福方案
      </p>
    </header>

    <div class="w-full mb-10">
      <div class="flex flex-wrap items-stretch -mx-5">
        <div class="w-full h-full px-5 mb-5 md:mb-0 md:w-1/2">
          <ProductCard
            :product_data="
              $store.state.product_data.filter((item) => item.GoodsID == 1)[0]
            "
            @change-amount="ChangeAmount"
          />
        </div>
        <div class="w-full px-5 md:w-1/2">
          <ProductCard
            :product_data="
              $store.state.product_data.filter((item) => item.GoodsID == 2)[0]
            "
            @change-amount="ChangeAmount"
          />
        </div>
      </div>
    </div>

    <div class="w-full p-5 border-2 border-red-700 md:p-10">
      <div class="flex flex-wrap items-stretch mb-5 -mx-5">
        <div class="w-full px-5 mb-5">
          <header class="w-full mb-5">
            <p class="mb-2 text-xl font-black text-red-700">資料填寫</p>
          </header>
        </div>
        <div class="w-full px-5 mb-10 lg:w-1/2 lg:mb-0">
          <div class="w-full mb-10">
            <ShippingList
              :form_data="form_data"
              :errors="errors"
              :checkout_data="checkout_data"
              @updateShipping="form_data.ship_way = $event"
            />
            <PaymentList
              :form_data="form_data"
              :errors="errors"
              :checkout_data="checkout_data"
              @updatePayment="form_data.pay_way = $event"
            />
          </div>

          <div>
            <div class="flex items-center justify-between w-full mb-3">
              <p>商品小計</p>
              <p>NT$ {{ product_total_price }}</p>
            </div>
            <div
              v-if="ship_price != 0"
              class="flex items-center justify-between w-full mb-3"
            >
              <p>運費</p>
              <p>NT$ {{ ship_price }}</p>
            </div>
            <div
              v-if="payment_price != 0"
              class="flex items-center justify-between w-full mb-3"
            >
              <p>手續費</p>
              <p>NT$ {{ payment_price }}</p>
            </div>
            <div class="flex items-center justify-between w-full">
              <p>總金額</p>
              <p class="text-lg font-bold">NT$ {{ total_price }}</p>
            </div>
          </div>
        </div>
        <div class="w-full px-5 lg:w-1/2">
          <UserForm
            :form_data="form_data"
            :errors="errors"
            :checkout_data="checkout_data"
            @update-form="UpdateForm"
            @set-samebuyer="SetSameBuyer"
          />
        </div>
      </div>
      <div class="flex flex-wrap pt-10 border-t border-black">
        <div class="w-full ml-auto lg:w-1/2">
          <p class="mb-5 text-sm xs:text-base">
            提交訂單表示同意<span class="mx-1 font-bold text-primary">{{
              $GetColumn("company_name")
            }}</span
            >的
            <router-link
              to="/terms/site_privacy"
              class="font-bold text-red-700 underline"
              >隱私政策</router-link
            >
            與
            <router-link
              to="/terms/return_policy"
              class="font-bold text-red-700 underline"
              >退換貨政策</router-link
            >
          </p>
          <button
            @click="Validate"
            class="block w-full py-3 font-bold text-white uppercase transition-colors duration-300 bg-red-700 border border-primary hover:md:text-red-700 md:hover:border-red-700 px-14 hover:md:bg-transparent"
          >
            立即下單
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PaymentList from "./ShopSection/PaymentList.vue";
import ShippingList from "./ShopSection/ShippingList.vue";
import UserForm from "./ShopSection/UserForm.vue";
import ProductCard from "./ShopSection/ProductCard.vue";
import { setLocalStorage } from "@/common/cookie";
import { getCashier, SendCheckout } from "@/api/shopcart";
import {
  validName,
  validEmail,
  validPhone,
  validAddress,
} from "@/common/validate";
import { Purchase, AddToCart } from "@/common/pixel_methods.js";
import {
  GTM_Purchase,
  GTM_AddShopCart,
  GTM_RemoveShopCart,
} from "@/common/gtm_methods.js";
export default {
  name: "ShopSection",
  components: {
    PaymentList,
    ShippingList,
    UserForm,
    ProductCard,
  },
  data() {
    return {
      form_data: {
        ship_way: "",
        consignee_name: "", //收件人姓名
        consignee_phone: "", //收件人電話
        consignee_city: "", //收件人地址
        consignee_area: "", //收件人地址
        consignee_address: "", //收件人地址
        consignee_email: "", //購買人信箱
        buyer_name: "", //購買人姓名
        buyer_phone: "", //收購買人電話
        comment: "",
        pay_way: "",
        outlying: false,
        coupon: "",
        shop_id: "",
        shop_name: "",
        shop_address: "",
        bank_account: "",
        installment: "",
        auto_register: true,
        amount: 1,
      },
      errors: [],
      checkout_data: null,
    };
  },
  methods: {
    ChangeAmount({ product_data, active_option, amount }) {
      amount == 1
        ? this.Add(product_data, active_option)
        : this.Remove(product_data, active_option);
    },
    UpdateForm({ key, val }) {
      this.form_data[key] = val;
      this.$set(this.form_data, key, val);
      if (key == "city") {
        this.$set(this.form_data, "area", "");
      }
      if (key == "city" || key == "area") {
        if (
          val == "蘭嶼鄉" ||
          val == "連江縣" ||
          val == "綠島鄉" ||
          val == "澎湖縣" ||
          val == "金門縣"
        ) {
          this.$set(this.form_data, "outlying", true);
        } else {
          this.$set(this.form_data, "outlying", false);
        }
      }
      if (key == "shipway") {
        this.form_data.shop_id = "";
        this.form_data.shop_name = "";
        this.form_data.shop_address = "";
      }
      if (key == "ship_way" || key == "pay_way") {
        this.GetCashier();
      }
    },
    Validate() {
      this.errors = [];
      this.form_data.ship_way == "" ? this.errors.push("ship_way") : "";

      if (this.form_data.ship_way != "") {
        const ship_data = this.shipway_data.filter(
          (item) => item.ShippingID == this.form_data.ship_way
        )[0];
        if (["UNIMARTC2C", "FAMIC2C"].indexOf(ship_data.ShippingType) == -1) {
          this.form_data.consignee_city == ""
            ? this.errors.push("consignee_city")
            : "";
          this.form_data.consignee_area == ""
            ? this.errors.push("consignee_area")
            : "";
          !validAddress(this.form_data.consignee_address)
            ? this.errors.push("consignee_address")
            : "";
        } else {
          this.form_data.shop_id == "" ? this.errors.push("shop_id") : "";
        }
      }

      this.form_data.pay_way == "" ? this.errors.push("pay_way") : "";
      if (this.form_data.pay_way != "") {
        const pay_data = this.payment_data.filter(
          (item) => item.PaymentID == this.form_data.pay_way
        )[0];
        if (
          pay_data.PaymentType == "NormalATM" &&
          this.form_data.bank_account == ""
        ) {
          this.errors.push("bank_account");
        } else if (
          pay_data.PaymentType == "ZeroCard" &&
          this.form_data.installment == ""
        ) {
          this.errors.push("installment");
        }
      }

      let final_price = this.form_data.outlying
        ? this.checkout_data.FinalTotalOutlying
        : this.checkout_data.FinalTotal;
      if (
        parseInt(final_price) >
        parseInt(this.checkout_data.PaymentInfo.MaxAmount)
      ) {
        this.errors.push("max_payment");
        this.$store.commit("SetDialog", {
          status: true,
          content: `很抱歉！<br/>購物車總金額超出付款方式的上限，請選擇其他付款方式<br/>如有任何需求請<a class="underline text-secondary" href="/contact" target="_blank">聯絡我們</a>`,
        });
      }

      if (!validName(this.form_data.buyer_name)) {
        this.errors.push("buyer_name");
      }
      !validPhone(this.form_data.buyer_phone)
        ? this.errors.push("buyer_phone")
        : "";

      if (!validName(this.form_data.consignee_name)) {
        this.errors.push("consignee_name");
      }
      !validEmail(this.form_data.consignee_email)
        ? this.errors.push("consignee_email")
        : "";
      !validPhone(this.form_data.consignee_phone)
        ? this.errors.push("consignee_phone")
        : "";
      this.form_data.comment == "" ? this.errors.push("comment") : "";

      if (this.errors.length > 0) {
        var element = document.getElementById("ShopSection");
        var headerOffset = 100;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      } else {
        this.SendData();
      }
    },
    SetSameBuyer() {
      this.form_data.consignee_name = this.form_data.buyer_name;
      this.form_data.consignee_phone = this.form_data.buyer_phone;
    },
    Init() {
      const product = this.$store.state.product_data.filter(
        (item) => item.GoodsID == 1
      )[0];
      const shop_cart_item = {
        product: JSON.parse(JSON.stringify(product)),
        options: JSON.parse(
          JSON.stringify([product.Stock[0].ColorID, product.Stock[0].SizeID])
        ),
        amount: 1,
        show_message: false,
      };
      this.$store.commit("shopcart_module/AddShopCart", shop_cart_item);
      this.GetCashier();
    },
    Add(product_data, active_option) {
      const shop_cart_item = {
        product: product_data,
        options: active_option,
        amount: 1,
      };
      this.$store.commit("shopcart_module/AddShopCart", shop_cart_item);
      GTM_AddShopCart(shop_cart_item, 1);
      AddToCart(product_data, active_option, 1);

      this.$nextTick(() => {
        this.GetCashier();
      });
    },
    Remove(product_data, active_option) {
      let shop_cart_index = -1;
      this.shopcart.forEach((item, index) => {
        if (
          item.product_data.GoodsID == product_data.GoodsID &&
          item.active_option[0] == active_option[0] &&
          item.active_option[1] == active_option[1]
        ) {
          shop_cart_index = index;
        }
      });
      if (shop_cart_index != -1 && this.shopcart[shop_cart_index].amount > 0) {
        this.$store.commit("shopcart_module/RemoveShopCart", {
          index: shop_cart_index,
          amount: 1,
        });
        GTM_RemoveShopCart(this.shopcart[shop_cart_index], 1);

        this.$nextTick(() => {
          this.GetCashier();
        });
      }
    },
    GetCashier() {
      if (this.shopcart.length > 0) {
        this.$store.commit("SetLoading", 1);
        getCashier(
          this.form_data.coupon,
          this.form_data.pay_way,
          this.form_data.ship_way,
          this.shopcart
        ).then((res) => {
          this.$store.commit("SetLoading", -1);
          if (res.code == 200) {
            this.checkout_data = res.data;
          } else if (res.msg.indexOf("客製化商品資訊錯誤") != -1) {
            const product_id = res.msg.split("(")[1].split(")")[0];
            const product_data = this.shopcart.filter(
              (item) => item.GoodsID == product_id
            )[0];
            this.$store.commit("SetDialog", {
              status: true,
              content:
                product_data.Title + "，商品錯誤，請將商品移除後再次結帳。",
            });
          } else if (res.msg.indexOf("商品資訊錯誤") != -1) {
            let product_string = res.msg.split("(")[1].split(")")[0];
            const product_id = product_string.split(",")[0];
            // const color_id = product_string.split(",")[1];
            // const size_id = product_string.split(",")[2];

            const product_data = this.shopcart.filter(
              (item) => item.GoodsID == product_id
            )[0];
            this.$store.commit("SetDialog", {
              status: true,
              content:
                product_data.Title + "，商品錯誤，請將商品移除後再次結帳。",
            });
          } else if (res.msg.indexOf("請重新選擇其他運送方式") != -1) {
            this.form_data.ship_way = "";
            setLocalStorage("check_out_form", JSON.stringify(this.form_data));
          } else if (res.msg.indexOf("您所輸入的優惠代碼無法使用") != -1) {
            this.$store.commit("SetDialog", {
              status: true,
              content: res.msg,
            });
            this.form_data.coupon = "";
          } else if (res.msg.indexOf("請重新選擇其他付款方式") != -1) {
            this.form_data.pay_way = "";
            setLocalStorage("check_out_form", JSON.stringify(this.form_data));
            this.GetCashier();
          } else if (
            res.msg.indexOf("您選擇的付款方式需配合選擇指定的運送方式") != -1
          ) {
            this.form_data.ship_way = "";
            setLocalStorage("check_out_form", JSON.stringify(this.form_data));
            this.GetCashier();
          } else {
            this.$store.commit("SetDialog", {
              status: true,
              content: res.msg,
            });
            this.$router.push("/");
          }
        });
      } else {
        this.checkout_data = null;
      }
    },
    SendData() {
      let form_data = Object.assign({}, this.form_data);
      const pay_data = this.payment_data.filter(
        (item) => item.PaymentID == this.form_data.pay_way
      )[0];
      if (pay_data.PaymentType == "NormalATM") {
        form_data.comment != "" ? (form_data.comment += "\n") : "";
        form_data.comment += "匯款帳號末五碼：" + this.form_data.bank_account;
      }

      this.$store.commit("SetLoading", 1);
      SendCheckout(form_data, this.shopcart).then((res) => {
        if (res.code == 200) {
          this.$store.commit("SetLoading", -1);
          // Pixel 送出訂單
          Purchase(
            this.shopcart,
            res.data.TradeData,
            parseInt(this.checkout_data.FinalTotal),
            this.form_data
          );
          // GA 送出訂單
          GTM_Purchase(
            this.shopcart,
            res.data.TradeData,
            this.form_data.coupon,
            this.form_data
          );

          setLocalStorage("trade_data", JSON.stringify(this.form_data));
          setLocalStorage("trade_shopcart", JSON.stringify(this.shopcart));
          setLocalStorage(
            "trade_checkout_data",
            JSON.stringify(this.checkout_data)
          );
          this.$store.commit("shopcart_module/SetShopCart", []);
          if (
            this.checkout_data.PaymentInfo.PaymentType == "PCHomeCredit" ||
            this.checkout_data.PaymentInfo.PaymentType == "PCHomeATM"
          ) {
            window.location.replace(res.data.PaymentHTML);
          } else if (
            this.checkout_data.PaymentInfo.PaymentType == "NormalATM"
          ) {
            this.$store.commit("SetLoading", -1);
            this.$router.push(`/order_create/${res.data.TradeData.TradeID}`);
          } else if (this.checkout_data.PaymentInfo.PaymentType == "COD") {
            this.$store.commit("SetLoading", -1);
            this.$router.push(`/order_create/${res.data.TradeData.TradeID}`);
          } else if (this.checkout_data.PaymentInfo.PaymentType == "LINEPAY") {
            window.location.replace(res.data.PaymentHTML);
          } else if (this.checkout_data.PaymentInfo.PaymentType == "Paypal") {
            window.location.replace(res.data.PaymentHTML);
          } else if (this.checkout_data.PaymentInfo.PaymentType == "ZeroCard") {
            window.location.replace(res.data.PaymentHTML);
          } else if (
            ["FAMIC2C", "UNIMARTC2C"].indexOf(
              this.checkout_data.PaymentInfo.PaymentType
            ) != -1
          ) {
            this.$store.commit("SetLoading", -1);
            this.$router.push(`/order_create/${res.data.TradeData.TradeID}`);
          } else if (
            this.checkout_data.PaymentInfo.PaymentGateway == "NewebPay"
          ) {
            document
              .querySelector("body")
              .insertAdjacentHTML("afterend", res.data.PaymentHTML);
            document.querySelector("#NewebPay").submit();
          } else {
            document
              .querySelector("body")
              .insertAdjacentHTML("afterend", res.data.PaymentHTML);
            document.querySelector("#ecpay-form").submit();
          }
        } else if (res.code == 500) {
          this.$store.commit("SetLoading", -1);
          this.$store.commit("SetDialog", {
            status: true,
            content: res.msg,
          });
        }
      });
    },
  },
  computed: {
    shopcart() {
      return this.$store.state.shopcart_module.shopcart;
    },
    total_price() {
      if (this.checkout_data == null) {
        return 0;
      }
      if (this.form_data.outlying) {
        return this.checkout_data.FinalTotalOutlying;
      } else {
        return this.checkout_data.FinalTotal;
      }
    },
    product_total_price() {
      if (this.checkout_data == null) {
        return 0;
      }
      return this.checkout_data.DiscountFullTotal;
    },
    ship_price() {
      if (this.checkout_data == null) {
        return 0;
      } else {
        return this.form_data.outlying
          ? this.checkout_data.ShippingFeeOutlying
          : this.checkout_data.ShippingFee;
      }
    },
    payment_price() {
      if (this.checkout_data == null) {
        return 0;
      } else {
        return this.checkout_data.PaymentSubtotalFee;
      }
    },
    // 取得物流方式
    shipway_data() {
      let shipway_list = JSON.parse(
        JSON.stringify(this.$store.state.shipway_data)
      );

      // 過濾停用與刪除的運送方式
      shipway_list = shipway_list.filter(
        (item) => item.Status == "Y" && item.deleted_at == null
      );
      // 如果目前商品中有低溫商品，則過濾出所有低溫物流
      if (this.has_forzen_product) {
        shipway_list = shipway_list.filter(
          (item) => item.DeliveryFrozen == "Y"
        );
      }
      // 如果目前商品中沒有低溫商品，則過濾出所有非低溫物流
      else {
        shipway_list = shipway_list.filter(
          (item) => item.DeliveryFrozen == "N"
        );
      }
      // 過濾出積材上限比目前購物車積材總合高的物流
      if (this.checkout_data != null) {
        shipway_list = shipway_list.filter(
          (item) =>
            item.DeliverVolumeMax >= this.checkout_data.TotalDeliverVolume &&
            item.DeliverWeightMax >= this.checkout_data.TotalDeliverWeight
        );
      }

      return shipway_list;
    },
    // 取得付款方式
    payment_data() {
      let payment_list = this.$store.state.payment_data.filter(
        (item) =>
          item.PaymentType != "UNIMARTC2C" &&
          item.PaymentType != "FAMIC2C" &&
          item.Status == "Y" &&
          item.deleted_at == null
      );
      // 如果選擇 7-11物流則加入 7-11取貨付款
      if (
        this.active_shipway_data &&
        this.active_shipway_data.ShippingType == "UNIMARTC2C"
      ) {
        const unimart_payment = this.$store.state.payment_data.filter(
          (item) => item.PaymentType == "UNIMARTC2C"
        );
        payment_list = payment_list.filter((item) => item.PaymentType != "COD");
        if (unimart_payment.length > 0) {
          payment_list = [...payment_list, ...unimart_payment];
        }
      }
      // 如果選擇 全家物流則加入 全家取貨付款
      else if (
        this.active_shipway_data &&
        this.active_shipway_data.ShippingType == "FAMIC2C"
      ) {
        const fami_payment = this.$store.state.payment_data.filter(
          (item) => item.PaymentType == "FAMIC2C"
        );
        payment_list = payment_list.filter((item) => item.PaymentType != "COD");
        if (fami_payment.length > 0) {
          payment_list = [...payment_list, ...fami_payment];
        }
      }

      payment_list = payment_list.filter((item) => {
        return parseInt(item.MaxAmount) > parseInt(this.total_price);
      });

      return payment_list;
    },
  },
  created() {
    this.form_data.ship_way = this.shipway_data[0].ShippingID;
    this.form_data.pay_way = this.payment_data[0].PaymentID;
    if (this.shopcart.length <= 0) {
      this.Init();
    } else {
      this.form_data.amount = this.shopcart[0].amount;
      this.GetCashier();
    }
  },
};
</script>