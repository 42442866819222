<template>
  <div class="fixed top-0 left-0 z-30 w-full">
    <div
      ref="AnnouncementMarquee"
      v-if="announcement_list.length > 0"
      class="relative z-20 w-full h-8 py-1 overflow-hidden text-sm font-medium text-center text-white bg-zinc-800"
    >
      <p
        v-for="(item, item_index) in announcement_list"
        :key="`announcement_${item_index}`"
        :class="announcement_list.length == 1 ? 'top-0' : 'top-full'"
        class="absolute left-0 flex items-center justify-center w-full h-full overflow-hidden whitespace-nowrap"
      >
        <span v-if="item.link == ''">
          {{ item.content }}
        </span>
        <a
          v-else
          :href="item.link"
          target="_blank"
          :key="`announcement_${item_index}`"
          class="flex items-center justify-center w-full h-full overflow-hidden whitespace-nowrap"
        >
          {{ item.content }}
        </a>
      </p>
    </div>
    <div class="relative">
      <div class="w-full relative z-10 h-[70px] bg-white">
        <div
          class="w-full max-w-[1920px] relative mx-auto flex items-center h-full px-5 justify-between z-10"
        >
          <router-link to="/" class="relative z-10 flex items-center h-full">
            <img
              :src="$ImageUrl($GetColumn('header_logo'))"
              class="block w-28"
            />
          </router-link>

          <div class="flex items-center h-full">
            <div class="relative">
              <button
                @click="ScrollToEl"
                class="relative z-10 px-5 py-2 text-sm font-bold text-white bg-red-700 md:text-base"
              >
                線上登記祈福
              </button>
              <span class="wave_shadow"></span>
              <span class="wave_shadow second"></span>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="shop_cart_dialog ? 'translate-y-full' : ''"
        class="absolute bottom-0 left-0 right-0 z-0 text-white transition-all duration-300 transform bg-green-700"
      >
        <div
          class="w-full max-w-[1440px] px-5 mx-auto xl:px-0 sm:px-10 py-4 relative flex items-center md:justify-center justify-between"
        >
          已將商品加入您的購物車！
          <span
            @click="OpenShopCart"
            class="block ml-2 text-xs font-medium underline cursor-pointer"
            >查看購物車</span
          >

          <button
            @click="CloseShopCartDialog"
            class="absolute hidden text-xl transform -translate-y-1/2 md:block xl:right-0 sm:right-10 right-5 top-1/2"
          >
            <i class="icon-close"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AnnouncementMarquee } from "@/gsap/AnnouncementMarquee";
export default {
  name: "MainHeader",
  data() {
    return {
      menu_list: [
        {
          title: "商品列表",
          sub_title: "Products",
          link: "/collections",
        },
        {
          title: "關於我們",
          sub_title: "About Us",
          link: "/about",
        },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        {
          title: "影音專區",
          sub_title: "Videos",
          link: "/video",
        },
        {
          title: "聯絡我們",
          sub_title: "Contact",
          link: "/contact",
        },
      ],
      drop_down: false,
      dialog_timer: null,
      announcement_gsap: null,
    };
  },
  props: {
    menu_status: {
      require: true,
      type: Boolean,
    },
  },
  methods: {
    OpenShopCart() {
      this.$store.commit("SetShopCartStatus", true);
    },
    CloseShopCartDialog() {
      this.$store.commit("SetShopCartDialog", false);
    },
    OpenSearchDialog() {
      this.$store.commit("SetSearchDialog", true);
    },

    ScrollToEl() {
      var element = document.getElementById("ShopSection");
      var headerOffset = 100;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
  },
  computed: {
    shop_cart_dialog() {
      return this.$store.state.shop_cart_dialog;
    },
    shop_cart_data() {
      return this.$store.state.shopcart_module.shopcart;
    },
    shop_cart_count() {
      let count = 0;
      this.shop_cart_data.forEach((item) => {
        count += parseInt(item.amount);
      });
      return count;
    },
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
  },
  watch: {
    shop_cart_dialog() {
      if (this.shop_cart_dialog) {
        this.dialog_timer != null ? clearTimeout(this.dialog_timer) : "";
        this.dialog_timer = setTimeout(() => {
          this.CloseShopCartDialog();
        }, 5000);
      }
    },
  },
  mounted() {
    if (this.announcement_list.length > 1) {
      this.announcement_gsap = new AnnouncementMarquee(
        this.$refs.AnnouncementMarquee
      );
      this.announcement_gsap.setup();
    }
  },
};
</script>
<style scoped>
.wave_shadow {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: shadow_grow 2s infinite linear;
}
.wave_shadow.second {
  animation: shadow_grow 2.5s infinite linear;
}

@keyframes shadow_grow {
  0% {
    box-shadow: 0 0 5px 0px rgba(255, 0, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 10px 8px rgba(255, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 20px 8px rgba(255, 0, 0, 0);
  }
}
</style>