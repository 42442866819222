<template>
  <div class="mb-10">
    <div class="flex flex-wrap items-center pb-2 mb-5 border-b border-black">
      <p class="font-bold">付款方式</p>

      <p v-if="GetError('pay_way')" class="ml-5 text-sm text-red-600">
        請選擇付款方式
      </p>
    </div>
    <ol class="flex flex-wrap items-stretch -mx-2">
      <li
        v-for="(item, item_index) in payment_data"
        :key="`payment_${item_index}`"
        class="px-2 mb-4"
      >
        <button
          @click="$emit('updatePayment', item.PaymentID)"
          class="block px-4 py-1 transition-colors duration-200 border border-black cursor-pointer hover:bg-zinc-300"
          :class="
            item.PaymentID == form_data.pay_way ? 'bg-red-700 text-white' : ''
          "
        >
          <span class="text-base font-bold">{{ item.Title }} </span>
        </button>
      </li>
    </ol>

    <div
      v-if="
        active_payment_data != null &&
        active_payment_data.PaymentType == 'NormalATM'
      "
      class="w-full p-5 text-xs"
    >
      <div class="mb-4">
        <input
          type="text"
          name="bank_account"
          :value="form_data.bank_account"
          @input="UpdateForm('bank_account', $event.target.value)"
          class="relative z-0 w-40 max-w-full px-4 py-3 border rounded-md appearance-none border-zinc-300 focus:outline-primary"
          placeholder="您的匯款帳號末五碼"
        />
        <p v-if="GetError('bank_account')" class="text-xs text-red-600">
          請輸入您的匯款帳號末五碼
        </p>
      </div>
      <div class="mb-4">
        <p class="text-sm font-bold">匯款資訊({{ normal_atm_info[0] }})</p>
        <p class="text-xl font-bold">
          {{ normal_atm_info[1] }} - {{ normal_atm_info[2] }}
        </p>
      </div>
      <p class="mb-1">匯款注意事項：</p>
      <p class="mb-1">
        1.匯款時請<b class="text-primary">不要於匯款備註填寫任何文字</b
        >以提高查帳效率
      </p>
      <p>2.匯款後主動聯繫告知匯款能夠提升您的訂單查帳與出貨速度</p>
    </div>

    <div
      v-if="
        active_payment_data != null &&
        active_payment_data.PaymentType == 'ZeroCard'
      "
      class="w-full p-5 text-xs"
    >
      <div class="mb-4">
        <p>分期期數：</p>
        <div class="relative w-48 max-w-full">
          <i
            class="absolute z-10 text-black transform -translate-y-1/2 pointer-events-none top-1/2 right-4 icon-chevron-down"
          ></i>
          <select
            type="text"
            name="bank_account"
            :value="form_data.installment"
            @input="UpdateForm('installment', $event.target.value)"
            class="relative z-0 w-full px-4 py-3 pr-10 border rounded-md appearance-none border-zinc-300 focus:outline-primary"
          >
            <option value="">請選擇分期期數</option>
            <option value="6">6期</option>
            <option value="9">9期</option>
            <option value="12">12期</option>
            <option value="18">18期</option>
            <option value="24">24期</option>
          </select>
        </div>
        <p v-if="GetError('installment')" class="text-xs text-red-600">
          請選擇分期期數
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentList",
  props: {
    form_data: {
      type: Object,
    },
    errors: {
      type: Array,
    },
    checkout_data: {
      type: Object,
    },
  },
  methods: {
    GetError(key) {
      return this.errors.indexOf(key) != -1;
    },
  },
  computed: {
    total_price() {
      if (this.checkout_data == null) {
        return 0;
      }
      if (this.form_data.outlying) {
        return this.checkout_data.FinalTotalOutlying;
      } else {
        return this.checkout_data.FinalTotal;
      }
    },
    // 取得物流方式
    shipway_data() {
      let shipway_list = JSON.parse(
        JSON.stringify(this.$store.state.shipway_data)
      );

      // 過濾停用與刪除的運送方式
      shipway_list = shipway_list.filter(
        (item) => item.Status == "Y" && item.deleted_at == null
      );
      // 如果目前商品中有低溫商品，則過濾出所有低溫物流
      if (this.has_forzen_product) {
        shipway_list = shipway_list.filter(
          (item) => item.DeliveryFrozen == "Y"
        );
      }
      // 如果目前商品中沒有低溫商品，則過濾出所有非低溫物流
      else {
        shipway_list = shipway_list.filter(
          (item) => item.DeliveryFrozen == "N"
        );
      }
      // 過濾出積材上限比目前購物車積材總合高的物流
      if (this.checkout_data != null) {
        shipway_list = shipway_list.filter(
          (item) =>
            item.DeliverVolumeMax >= this.checkout_data.TotalDeliverVolume &&
            item.DeliverWeightMax >= this.checkout_data.TotalDeliverWeight
        );
      }

      return shipway_list;
    },
    // 選中的物流方式
    active_shipway_data() {
      if (this.form_data.ship_way == "") {
        return null;
      } else {
        return this.shipway_data.filter(
          (item) => item.ShippingID == this.form_data.ship_way
        )[0];
      }
    },
    // 取得付款方式
    payment_data() {
      let payment_list = this.$store.state.payment_data.filter(
        (item) =>
          item.PaymentType != "UNIMARTC2C" &&
          item.PaymentType != "FAMIC2C" &&
          item.Status == "Y" &&
          item.deleted_at == null
      );
      // 如果選擇 7-11物流則加入 7-11取貨付款
      if (
        this.active_shipway_data &&
        this.active_shipway_data.ShippingType == "UNIMARTC2C"
      ) {
        const unimart_payment = this.$store.state.payment_data.filter(
          (item) => item.PaymentType == "UNIMARTC2C"
        );
        payment_list = payment_list.filter((item) => item.PaymentType != "COD");
        if (unimart_payment.length > 0) {
          payment_list = [...payment_list, ...unimart_payment];
        }
      }
      // 如果選擇 全家物流則加入 全家取貨付款
      else if (
        this.active_shipway_data &&
        this.active_shipway_data.ShippingType == "FAMIC2C"
      ) {
        const fami_payment = this.$store.state.payment_data.filter(
          (item) => item.PaymentType == "FAMIC2C"
        );
        payment_list = payment_list.filter((item) => item.PaymentType != "COD");
        if (fami_payment.length > 0) {
          payment_list = [...payment_list, ...fami_payment];
        }
      }

      payment_list = payment_list.filter((item) => {
        return parseInt(item.MaxAmount) > parseInt(this.total_price);
      });

      return payment_list;
    },
    // 選中的付款方式
    active_payment_data() {
      if (this.form_data.pay_way == "") {
        return null;
      } else {
        return this.payment_data.filter(
          (item) => item.PaymentID == this.form_data.pay_way
        )[0];
      }
    },
    // 匯款帳號資料
    normal_atm_info() {
      return this.$GetColumn("ATMInfo").split(",");
    },
  },
};
</script>