import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/order_create/:id",
    name: "trade_finish_page",
    component: () => import("../views/TradeFinishView.vue"),
  },
  {
    path: "/terms/:id",
    name: "terms_page",
    component: () => import("../views/TermsView.vue"),
  },
  {
    path: "/order/search",
    name: "order_search_page",
    component: () => import("../views/OrderSearchView.vue"),
  },
  {
    path: "/order/result",
    name: "order_search_result_page",
    component: () => import("../views/OrderSearchResultView.vue"),
  },
  {
    path: "/error_page",
    name: "error_page",
    component: () => import("../views/ErrorView.vue"),
  },
  {
    path: "*",
    redirect: "/error_page",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // 換頁滾動至最上面
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (hasQueryParams(from) && from.query.order_memo) {
    if (to.query && to.query.order_memo) {
      next();
    } else {
      let new_query = null;
      new_query = to.query ? to.query : from.query;
      if (!to.order_memo) {
        new_query.order_memo = from.query.order_memo;
      }
      next({ name: to.name, query: new_query, params: to.params });
    }
  } else {
    next();
  }
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

export default router;
