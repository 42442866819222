var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-10"},[_c('div',{staticClass:"flex flex-wrap items-center pb-2 mb-5 border-b border-black"},[_c('p',{staticClass:"font-bold"},[_vm._v("付款方式")]),(_vm.GetError('pay_way'))?_c('p',{staticClass:"ml-5 text-sm text-red-600"},[_vm._v(" 請選擇付款方式 ")]):_vm._e()]),_c('ol',{staticClass:"flex flex-wrap items-stretch -mx-2"},_vm._l((_vm.payment_data),function(item,item_index){return _c('li',{key:`payment_${item_index}`,staticClass:"px-2 mb-4"},[_c('button',{staticClass:"block px-4 py-1 transition-colors duration-200 border border-black cursor-pointer hover:bg-zinc-300",class:item.PaymentID == _vm.form_data.pay_way ? 'bg-red-700 text-white' : '',on:{"click":function($event){return _vm.$emit('updatePayment', item.PaymentID)}}},[_c('span',{staticClass:"text-base font-bold"},[_vm._v(_vm._s(item.Title)+" ")])])])}),0),(
      _vm.active_payment_data != null &&
      _vm.active_payment_data.PaymentType == 'NormalATM'
    )?_c('div',{staticClass:"w-full p-5 text-xs"},[_c('div',{staticClass:"mb-4"},[_c('input',{staticClass:"relative z-0 w-40 max-w-full px-4 py-3 border rounded-md appearance-none border-zinc-300 focus:outline-primary",attrs:{"type":"text","name":"bank_account","placeholder":"您的匯款帳號末五碼"},domProps:{"value":_vm.form_data.bank_account},on:{"input":function($event){return _vm.UpdateForm('bank_account', $event.target.value)}}}),(_vm.GetError('bank_account'))?_c('p',{staticClass:"text-xs text-red-600"},[_vm._v(" 請輸入您的匯款帳號末五碼 ")]):_vm._e()]),_c('div',{staticClass:"mb-4"},[_c('p',{staticClass:"text-sm font-bold"},[_vm._v("匯款資訊("+_vm._s(_vm.normal_atm_info[0])+")")]),_c('p',{staticClass:"text-xl font-bold"},[_vm._v(" "+_vm._s(_vm.normal_atm_info[1])+" - "+_vm._s(_vm.normal_atm_info[2])+" ")])]),_c('p',{staticClass:"mb-1"},[_vm._v("匯款注意事項：")]),_vm._m(0),_c('p',[_vm._v("2.匯款後主動聯繫告知匯款能夠提升您的訂單查帳與出貨速度")])]):_vm._e(),(
      _vm.active_payment_data != null &&
      _vm.active_payment_data.PaymentType == 'ZeroCard'
    )?_c('div',{staticClass:"w-full p-5 text-xs"},[_c('div',{staticClass:"mb-4"},[_c('p',[_vm._v("分期期數：")]),_c('div',{staticClass:"relative w-48 max-w-full"},[_c('i',{staticClass:"absolute z-10 text-black transform -translate-y-1/2 pointer-events-none top-1/2 right-4 icon-chevron-down"}),_c('select',{staticClass:"relative z-0 w-full px-4 py-3 pr-10 border rounded-md appearance-none border-zinc-300 focus:outline-primary",attrs:{"type":"text","name":"bank_account"},domProps:{"value":_vm.form_data.installment},on:{"input":function($event){return _vm.UpdateForm('installment', $event.target.value)}}},[_c('option',{attrs:{"value":""}},[_vm._v("請選擇分期期數")]),_c('option',{attrs:{"value":"6"}},[_vm._v("6期")]),_c('option',{attrs:{"value":"9"}},[_vm._v("9期")]),_c('option',{attrs:{"value":"12"}},[_vm._v("12期")]),_c('option',{attrs:{"value":"18"}},[_vm._v("18期")]),_c('option',{attrs:{"value":"24"}},[_vm._v("24期")])])]),(_vm.GetError('installment'))?_c('p',{staticClass:"text-xs text-red-600"},[_vm._v(" 請選擇分期期數 ")]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-1"},[_vm._v(" 1.匯款時請"),_c('b',{staticClass:"text-primary"},[_vm._v("不要於匯款備註填寫任何文字")]),_vm._v("以提高查帳效率 ")])
}]

export { render, staticRenderFns }